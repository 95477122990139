import { Controller } from '@hotwired/stimulus'

/**
 * Manages additional features related to VAT returns
 * @extends Controller
 */
export default class extends Controller {
  static values = {
    companyId: String,
    hasCombinedDraftVatReturns: Boolean,
    hasCombinedLockedVatReturns: Boolean
  }

  /**
   * Handles the toggle event for the feature checkbox
   * @param {Event} event - The toggle event
   */
  toggle(event) {
    const isChecked = event.target.checked

    if (!isChecked) {
      if (this.hasCombinedLockedVatReturnsValue) {
        this.showLockedVatReturnWarning(event.target)
      } else if (this.hasCombinedDraftVatReturnsValue) {
        this.showDraftVatReturnWarning(event.target)
      }
    }
  }

  /**
   * Shows warning dialog for locked VAT returns
   * @param {HTMLElement} target - The checkbox element
   */
  showLockedVatReturnWarning(target) {
    this.showWarningDialog({
      text: 'You have outstanding combined VAT returns. If you proceed, you will no longer be able to edit/unlock this return.',
      callback: isConfirmed => this.sweetAlertTriggeredForLock(isConfirmed, target)
    })
  }

  /**
   * Shows warning dialog for draft VAT returns
   * @param {HTMLElement} target - The checkbox element
   */
  showDraftVatReturnWarning(target) {
    this.showWarningDialog({
      text: 'You have combined draft VAT returns. If you proceed, the imported VAT return for the draft will be lost.',
      callback: isConfirmed => this.sweetAlertTriggeredForDraft(isConfirmed, target)
    })
  }

  /**
   * Displays a warning dialog with the specified options
   * @param {Object} options - Dialog options
   */
  showWarningDialog({ text, callback }) {
    sweetAlert({
      title: 'Warning!',
      text,
      html: true,
      type: 'warning',
      allowEscapeKey: true,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Keep active',
      customClass: 'sweetalert-scary-warning'
    }, callback)
  }

  /**
   * Handles the user's response for draft VAT returns
   * @param {boolean} isConfirmed - Whether the user confirmed the action
   * @param {HTMLElement} target - The checkbox element
   */
  sweetAlertTriggeredForDraft(isConfirmed, target) {
    if (isConfirmed) {
      this.deleteCombinedDraftVatReturn(target)
    } else {
      target.checked = true
    }
  }

  /**
   * Handles the user's response for locked VAT returns
   * @param {boolean} isConfirmed - Whether the user confirmed the action
   * @param {HTMLElement} target - The checkbox element
   */
  sweetAlertTriggeredForLock(isConfirmed, target) {
    if (!isConfirmed) {
      target.checked = true
    }
  }

  /**
   * Deletes the combined draft VAT return
   * @param {HTMLElement} target - The checkbox element
   */
  async deleteCombinedDraftVatReturn(target) {
    const url = `/imported_vat_returns/destroy_combined_draft/${this.companyIdValue}`
    const csrfToken = document.querySelector('[name="csrf-token"]')?.content

    if (!csrfToken) {
      this.handleError(target, new Error('CSRF token not found'))
      return
    }

    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': csrfToken,
          Accept: 'application/json'
        }
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.error || 'Server error')
      }

      this.handleSuccess(data.message)
    } catch (error) {
      this.handleError(target, error)
    }
  }

  /**
   * Handles successful deletion
   * @param {string} message - Success message
   */
  handleSuccess(message) {
    notify(
      message,
      'success',
      'The imported VAT return for the pending draft VAT return has been deleted.'
    )
  }

  /**
   * Handles errors during deletion
   * @param {HTMLElement} target - The checkbox element
   * @param {Error} error - The error object
   */
  handleError(target, error) {
    this.hasCombinedDraftVatReturnsValue = false
    target.checked = true
    notify(
      error,
      'danger',
      'An error occurred when trying to delete the imported VAT return for the pending draft VAT return.'
    )
  }
}
